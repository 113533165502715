import * as React from "react"

import { Checkbox, Form, Input ,Button  , message,Divider} from 'antd';
import {register} from '../../api/request'
import { Link ,navigate} from "gatsby";

const Register = (props) => {
    const {submit} =props
    const [form] = Form.useForm();
    const onFinish = (values: any) => {
        console.log('Received values of form: ', values );
        // submit()
        if(!(values?.password === values?.password2)){
            return message.error('两次输入的密码不一致');
        }
        register(values.username , values.password).then(res=>{
            console.log(res);
            message.success('注册成功')
            // submit()
            navigate('/login')
        })
    };
    
    return (
        <>
            <div className=" pb-5 flex items-baseline justify-between">
                <h3 className="text-2xl text-[#2E2F33]">注册</h3>
                <div className="text-[#8B8E9A]">
                    已有账号,
                    <Link to="/login" className="text-[#576AA4] cursor-pointer">去登录</Link>
                    {/* <span className="text-[#576AA4] cursor-pointer" onClick={()=>submit()}>去登录</span> */}
                </div>
            </div>
            <Form
                onFinish={onFinish}
                name="basic"
                layout="vertical"
                // form={form}
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                autoComplete="off"
            >
                {/* <Form.Item
                    label="手机"
                    name="phone"
                    rules={[{ required: true, message: ' ' }]}
                >
                    <Input className="w-[100%]" />
                </Form.Item> */}
                <Form.Item
                    label="邮箱"
                    required={false}
                    name="username"
                    rules={[{ required: true, message: ' ' ,type:'email'}]}
                >
                    <Input className="w-[100%]" />
                </Form.Item>

                <Form.Item
                    label="密码"
                    required={false}
                    name="password"
                    rules={[{ required: true, message: ' ' }]}
                >
                    <Input.Password className="w-[100%]" />
                </Form.Item>
                <Form.Item
                    label="确认密码"
                    required={false}
                    name="password2"
                    rules={[{ required: true, message: ' ' }]}
                >
                    <Input.Password className="w-[100%]" />
                </Form.Item>

                {/* <Form.Item noStyle valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Form.Item noStyle name="remember" valuePropName="checked">
                        <Checkbox  className=" bg-cyan-[#56BF8B]">Remember me</Checkbox>
                    </Form.Item>
                    <Form.Item noStyle name="remember2" valuePropName="checked">
                        <Checkbox  className=" bg-cyan-[#56BF8B]">Remember me</Checkbox>
                    </Form.Item>
                </Form.Item> */}

            <Form.Item className=" ">
                <Button className="w-[100%] mt-4" type="primary" htmlType="submit">
                    注册
                </Button>
            </Form.Item>

            </Form>

            {/* <Divider plain>
                <span className=" text-[#B9BECD] text-[12px]">快捷登录</span>
            </Divider> */}
        </>
    )
}


export default Register